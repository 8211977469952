<template>
  <Breadcrumbs></Breadcrumbs>
  <MDBContainer class="mt-3" bg="white">
    <MDBRow>
      <MDBCol>
        <MDBCard>
          <MDBCardBody>
            <form @submit="Submit" class="form">
              <MDBCardText>
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.DOMAIN") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input type="text" class="form-control" v-model="Form.smtp_domain" />
                    <span class="text-danger">{{ domainError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.PORT") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input type="number" class="form-control" v-model="Form.smtp_port" />
                    <span class="text-danger">{{ portError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.ENCRYPT") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input
                      type="text"
                      class="form-control"
                      v-model="Form.smtp_encryption"
                    />
                    <span class="text-danger">{{ encryptError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.USERNAME") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input
                      type="text"
                      class="form-control"
                      name="username"
                      v-model="Form.username"
                    />
                    <span class="text-danger">{{ usernameError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.PASSWORD") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input
                      type="text"
                      class="form-control"
                      name="password"
                      v-model="Form.password"
                    />
                    <span class="text-danger">{{ passwordError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.FROM") }}</label>
                  </MDBCol>
                  <MDBCol md="8">
                    <input
                      type="text"
                      class="form-control"
                      name="from"
                      v-model="Form.from"
                    />
                    <span class="text-danger">{{ fromError }}</span>
                  </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                  <MDBCol md="4">
                    <label>{{ $t("EMAIL.SYSCONFIG.TESTSEND") }}</label>
                  </MDBCol>
                  <MDBCol md="6">
                    <input
                      type="text"
                      class="form-control"
                      :placeholder="$t('EMAIL.SYSCONFIG.PLACEHOLDER')"
                      v-model="mail"
                    />
                    <span class="text-danger">{{ $t("EMAIL.SYSCONFIG.ATTENION") }}</span>
                  </MDBCol>
                  <MDBCol>
                    <MDBBtn color="primary" @click="sendMail" :disabled="sendMailLoading">
                      {{ $t("EMAIL.SYSCONFIG.TESTSEND") }}
                      <span
                        class="spinner-border Loading"
                        :class="{ 'd-none': !sendMailLoading }"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </span>
                    </MDBBtn>
                  </MDBCol>
                </MDBRow>
              </MDBCardText>
              <MDBRow>
                <MDBCol>
                  <MDBBtn color="primary" type="submit" :disabled="SubmitLoading">
                    {{ $t("COMMON.SAVE") }}
                    <span
                      class="spinner-border Loading"
                      :class="{ 'd-none': !SubmitLoading }"
                      role="status"
                    >
                      <span class="visually-hidden">Loading...</span>
                    </span>
                  </MDBBtn>
                </MDBCol>
              </MDBRow>
            </form>
          </MDBCardBody>
        </MDBCard>
      </MDBCol>
    </MDBRow>
  </MDBContainer>
</template>

<script>
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBCardText,
} from "mdb-vue-ui-kit";
import Breadcrumbs from "@/components/Breadcrumbs";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import { useForm, useField } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";
import { ref, reactive } from "vue";

export default {
  name: "EmailManage",
  components: {
    MDBContainer,
    Breadcrumbs,
    MDBRow,
    MDBCol,
    MDBCard,
    MDBCardBody,
    MDBCardText,
    MDBBtn,
  },
  setup() {
    const SubmitLoading = ref(false);
    const sendMailLoading = ref(false);
    const uuid = "5d9a499a-1b3a-4daf-96a3-24dbfad2ff34";
    const baseUrl = `Admin/v1/email/sysconfig`;
    const sendMailUrl = `/Admin/v1/email/send/test`;
    const mail = ref("");
    const i18n = useI18n();

    const basicSchema = {
      smtp_domain: yup.string().required().label(i18n.t("EMAIL.SYSCONFIG.DOMAIN")),
      smtp_port: yup.number().required().label(i18n.t("EMAIL.SYSCONFIG.PORT")),
      smtp_encryption: yup.string().required().label(i18n.t("EMAIL.SYSCONFIG.ENCRYPT")),
      username: yup.string().email().required().label(i18n.t("EMAIL.SYSCONFIG.USERNAME")),
      password: yup.string().required().label(i18n.t("EMAIL.SYSCONFIG.PASSWORD")),
      from: yup.string().email().required().label(i18n.t("EMAIL.SYSCONFIG.FROM")),
    };

    const FormSchema = yup.object(Object.assign(basicSchema));

    const { handleSubmit } = useForm({
      validationSchema: FormSchema,
    });

    const { value: smtp_domain, errorMessage: domainError } = useField("smtp_domain");
    const { value: smtp_port, errorMessage: portError } = useField("smtp_port");
    const { value: smtp_encryption, errorMessage: encryptError } = useField("smtp_encryption");
    const { value: username, errorMessage: usernameError } = useField("username");
    const { value: password, errorMessage: passwordError } = useField("password");
    const { value: from, errorMessage: fromError } = useField("from");

    const Form = reactive({
      smtp_domain,
      smtp_port,
      smtp_encryption,
      username,
      password,
      from,
    });

    // 取得 email sysconfig 資訊
    const getSettings = async () => {
      return await ApiService.get(baseUrl, uuid).then((response) => {
        if (response.status == 200) {
          const responseData = response.data.data;

          Form.smtp_domain = responseData.smtp_domain;
          Form.smtp_port = responseData.smtp_port;
          Form.smtp_encryption = responseData.smtp_encryption;
          Form.username = responseData.username;
          Form.password = responseData.password;
          Form.from = responseData.from;
        }
      });
    };

    // 資料送出
    const Submit = handleSubmit((values) => {
      SubmitLoading.value = true;
      ApiService.update(baseUrl, uuid, values).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("COMMON.EDIT")}${i18n.t("COMMON.SUCCESS")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });

          SubmitLoading.value = false;
        }
      });
      return false;
    });
    // 信件寄送
    const sendMail = () => {
      if (mail.value === "") return;
      const mailAry = mail.value.split(",");
      sendMailLoading.value = true;
      ApiService.post(sendMailUrl, { username: mailAry }).then((response) => {
        if (response.status == 200) {
          Swal.fire({
            title: "",
            text: `${i18n.t("EMAIL.SYSCONFIG.TESTMAILSENDED")}`,
            icon: "success",
            confirmButtonClass: i18n.t("COMMON.CONFIRM"),
          });

          sendMailLoading.value = false;
        }
      });
    };

    return {
      getSettings,
      SubmitLoading,
      sendMailLoading,
      Form,
      Submit,
      domainError,
      portError,
      encryptError,
      usernameError,
      passwordError,
      fromError,
      sendMail,
      mail,
    };
  },
  async mounted() {
    Promise.all([await this.getSettings()]).then(() => {
      this.PageLoading = false;
    });
  },
};
</script>
